<template>
  <div v-if="!isLoading">
    <HeaderPanel
      title="REDEEM Analysis"
      :filter="filter"
      @sidebar="sidebarFilter"
      :hasDropdown="false"
      :hasSearch="false"
      :hasExport="true"
      @export="exportCustomer"
    />
    <p class="my-2 f-16">
      Total Customer : {{ stat.totalCustomer }} ({{
        stat.totalCustomerPercent.toFixed(2)
      }}%)
    </p>
    <p class="my-2 f-16">
      {{
        dateTypeValue == 0
          ? `${$moment(filter.dateFrom).format("DD MMM YYYY")} - ${$moment(
              filter.dateTo
            ).format("DD MMM YYYY")}`
          : `${getDataFilter()}`
      }}
    </p>
    <StatPanel :column="statColumn" :data="stat" :isLoading="statLoading" />

    <div class="bg-white p-3">
      <b-card class="shadow-sm border-none text-black rounded-lg">
        <div>
          <div class="d-flex justify-content-between">
            <div class="chart-title-name">Redeem By Date</div>
            <div class="d-flex" style="column-gap: 5px">
              <div :class="`f-14 underline cursor-pointer`">
                <!-- @click="hadleChartFilter(chartFilter, chart)" -->
                <span
                  :class="filter.GraphType == '1' && 'text-highlight'"
                  class="text-underline text-filter"
                  @click="filterGraph('1')"
                  >Day</span
                >
              </div>
              <div :class="`f-14 underline cursor-pointer`">
                <!-- @click="hadleChartFilter(chartFilter, chart)" -->
                <span
                  :class="filter.GraphType == '2' && 'text-highlight'"
                  class="text-underline text-filter"
                  @click="filterGraph('2')"
                  >Month</span
                >
              </div>
              <div :class="`f-14 underline cursor-pointer`">
                <!-- @click="hadleChartFilter(chartFilter, chart)" -->
                <span
                  :class="filter.GraphType == '3' && 'text-highlight'"
                  class="text-underline text-filter"
                  @click="filterGraph('3')"
                  >Year</span
                >
              </div>
              <div :class="`f-14 underline cursor-pointer`">
                <!-- @click="hadleChartFilter(chartFilter, chart)" -->

                <span
                  class="text-highlight"
                  @click="exportExcel('1', 'redeem-analysis')"
                  >Export</span
                >
              </div>
            </div>
          </div>

          <Chart
            id="gender-chart"
            :chartData="chartGenderData"
            text="Number Of Customer"
            class="mt-auto"
            :label="chartGenderLabel"
            :hideLegend="false"
            v-if="!isChartLoading"
          />
        </div>
      </b-card>
      <b-card class="shadow-sm border-none text-black rounded-lg">
        <div class="d-flex justify-content-between">
          <div class="chart-title-name">Completed Redeem</div>
          <div class="d-flex align-items-center" style="column-gap: 5px">
            <b-form-input
              type="text"
              id="header-search-bar"
              class="search-bar mb-2"
              @keyup.enter="handleSearch"
              placeholder="Search Product, Coupon Name"
              v-debounce:500ms="handleSearch"
              v-model="filterTable.search"
              style="width: 300px"
            >
            </b-form-input>
            <div :class="`f-14 underline cursor-pointer`">
              <!-- @click="hadleChartFilter(chartFilter, chart)" -->
              <span
                class="text-highlight"
                @click="exportExcel('2', 'completed-redeem')"
                >Export</span
              >
            </div>
          </div>
        </div>
        <b-table
          fixed
          striped
          hover
          :fields="fields"
          :items="items"
          :busy="isBusy"
          show-empty
          empty-text="No matching records found"
          foot-clone
          no-footer-sorting
          @sort-changed="sortingTable"
          :sort-by.sync="filterTable.sortBy"
          no-local-sorting
          :sort-desc.sync="filterTable.sortDesc"
        >
          <template #cell(numOfRedeem)="{ item }">
            {{ item.numOfRedeem | numeral("0,0") }} ({{
              item.numOfRedeemPercent.toFixed(2)
            }}%)
          </template>
          <template v-slot:foot()="data">
            <span v-if="subTotal[data.field.key]" class="text-center"
              >{{ subTotal[data.field.key] | numeral("0,0.00") }}
            </span>
            <span v-else-if="data.field.key == 'branchATV' && subTotal.atv"
              >{{ subTotal.atv | numeral("0,0.00") }}fads</span
            >
            <span v-else-if="data.field.key == 'branchUPT' && ubTotal.upt">{{
              subTotal.upt | numeral("0,0.00")
            }}</span>
            <span v-else>
              <div class="text-left px-2" v-if="data.field.text">
                {{ data.field.text }}
              </div>
            </span>
          </template>
        </b-table>
        <Pagination
          @handleChangeTake="handleChangeTake"
          @pagination="pagination"
          :filter="filterTable"
          :rows="rows"
          :limit="2"
        />
      </b-card>
    </div>

    <SideBarFilter
      ref="sidebarFilter"
      :filter="filter"
      :placeholder="'Search Name'"
      @clearFilter="clearFilter"
      @searchAll="filterActions"
      :hideStatusFilter="false"
      :hideSearchBar="true"
    >
      <template v-slot:filter-option>
        <div>
          <InputSelect
            title="Transaction Date"
            name="transaction-date"
            class="mt-2"
            v-model="dateTypeValue"
            :options="options.dateType"
            valueField="value"
            textField="text"
          >
            <template v-slot:option-first>
              <b-form-select-option value="" disabled
                >-- Select Category --</b-form-select-option
              >
            </template>
          </InputSelect>
        </div>
        <div v-if="dateTypeValue == 0">
          <InputDatePickerFilter
            textFloat="Date From"
            name="date-from"
            ref="date-from"
            :value="filter.DateFrom"
            @input="(val) => (filter.DateFrom = val)"
          />
        </div>

        <div v-if="dateTypeValue == 0">
          <InputDatePickerFilter
            textFloat="Date To"
            name="date-to"
            ref="date-to"
            :value="filter.DateTo"
            @input="(val) => (filter.DateTo = val)"
          />
        </div>
        <div class="my-3">
          <label class="main-label">Member Tiers</label>
          <multiselect
            :close-on-select="false"
            :clear-on-select="false"
            multiple
            v-model="filter.MemberTier"
            :options="options.memberTiers"
            placeholder="Select Member Tier"
            track-by="id"
            label="memberTierName"
          ></multiselect>
        </div>
        <div class="mb-3">
          <label class="main-label">Redeem</label>
          <multiselect
            :close-on-select="false"
            :clear-on-select="false"
            multiple
            v-model="filter.RedeemName"
            :options="options.redeem"
            placeholder="Select Redeem"
            track-by="id"
            label="name"
          ></multiselect>
        </div>

        <div class="mb-3">
          <label class="main-label">Redeem Item</label>
          <multiselect
            :close-on-select="false"
            :clear-on-select="false"
            multiple
            v-model="filter.RedeemItem"
            :options="options.redeemItems"
            placeholder="Select Redeem Items"
            track-by="id"
            label="name"
          ></multiselect>
        </div>
      </template>
    </SideBarFilter>
    <ModalInputEmail
      ref="ModalInputEmail"
      :form="filterExportFile"
      @closeModal="clearExportFilter"
      @changeEmail="(val) => (filterExportFile.email = val)"
      @submit="exportCustomer"
    />
  </div>
</template>

<script>
import ModalInputEmail from "@/components/customer/ModalInputEmail";
import Chart from "./chart";

function getRandomColor() {
  const r = Math.floor(Math.random() * 256);
  const g = Math.floor(Math.random() * 256);
  const b = Math.floor(Math.random() * 256);
  return "rgb(" + r + "," + g + "," + b + ")";
}
import Multiselect from "vue-multiselect";
const moment = require("moment");
import InputDatePickerFilter from "@/components/inputs/InputDatePickerFilter";
export default {
  components: {
    Chart,
    Multiselect,
    InputDatePickerFilter,
    ModalInputEmail,
  },
  data() {
    return {
      isBusy: false,
      isProductBusy: false,
      isChartLoading: true,
      fields: [
        {
          key: "redeemName",
          label: "Redeem Name",
          sortable: true,
          text: "Total",
        },
        { key: "redeemItemName", label: "Redeem Item Name", sortable: true },
        { key: "typeOfRedeem", label: "Type of Redeem", sortable: true },
        {
          key: "numOfRedeem",
          label: "Number of Redeem % of User Compare by Total User",
          sortable: true,
        },
      ],
      statColumn: [
        {
          name: "Total Redeem",
          key: "totalRedeem",
          isPercent: false,
          isDecimal: false,
        },

        {
          name: "Total Customer",
          key: "totalCustomer",
          isPercent: false,
          isDecimal: false,
        },
      ],
      statLoading: true,
      stat: {
        totalCustomer: 0,
        totalCustomerPercent: 0,
        totalRedeem: 0,
        dateFrom: "2023-04-01T00:00:00",
        dateTo: "2023-05-30T00:00:00",
      },
      filterExportFile: {
        email: "",
      },
      filter: {
        DateFrom: moment().subtract(30, "days").format(),
        DateTo: moment().format(),
        Search: "",
        MemberTier: [],
        RedeemName: [],
        RedeemItem: [],
        Page: 1,
        GraphType: 2,
        take: 10,
      },
      dateTypeValue: 2,

      filterTable: {
        page: 1,
        take: 10,
        search: "",
        SortColumnId: 0,
        SortType: true,
        sortBy: "",
        sortDesc: "",
      },

      loading: true,
      loading2: true,
      items: [],

      rows: 0,
      rowsProduct: 0,
      chartAgeData: [],
      chartAgeLabel: [],
      chartGenderData: [],
      chartGenderLabel: [],
      options: {
        memberTiers: [],
        redeem: [],
        redeemItems: [],

        dateType: [
          { text: "Last 7 Days", value: 1 },
          { text: "Last 30 Days", value: 2 },
          { text: "Last 365 Days", value: 3 },
          { text: "Custom", value: 0 },
        ],
        // product: [],
        // transactionDate: [],
      },
      subTotal: {},
    };
  },
  async created() {
    this.isLoading = true;
    this.getData();
    this.getOptions();
    this.isLoading = false;
  },
  methods: {
    async getData() {
      return await Promise.all([
        this.getStat(),
        this.getTotal(),
        this.getRedeemChart(),
        this.getCompletedRedeem(),
        // this.getProduct(),
      ]);
    },
    async getStat() {
      this.statLoading = true;
      let payLoad = { ...this.filter };
      for (const [keys, value] of Object.entries(payLoad)) {
        if (typeof value == "object") {
          payLoad[keys] = value.map((el) => el.id);
        }
      }
      const res = await this.$Axios.post(
        `${process.env.VUE_APP_REPORT_API}/dashboard/RedeemAnalysis`,
        payLoad
      );
      this.statLoading = false;
      this.stat = res.data.detail;
    },
    async getTotal() {
      let payLoad = { ...this.filter };
      for (const [keys, value] of Object.entries(payLoad)) {
        if (typeof value == "object") {
          payLoad[keys] = value.map((el) => el.id);
        }
      }
      const res = await this.$Axios.post(
        `${process.env.VUE_APP_REPORT_API}/Dashboard/RedeemAnalysis/CompletedRedeem_Subtotal`,
        payLoad
      );
      this.subTotal = res.data.detail;
    },

    async getRedeemChart() {
      let payLoad = { ...this.filter };
      for (const [keys, value] of Object.entries(payLoad)) {
        if (typeof value == "object") {
          payLoad[keys] = value.map((el) => el.id);
        }
      }

      this.isChartLoading = true;
      const res = await this.$Axios.post(
        `${process.env.VUE_APP_REPORT_API}/dashboard/RedeemAnalysis/Graph`,
        payLoad
      );
      if (payLoad.GraphType == 1) {
        this.chartGenderLabel = res.data.detail.map((el) =>
          moment(el.group, "MM/DD/YYYY").format("DD/MM/YYYY")
        );
      } else {
        this.chartGenderLabel = res.data.detail.map((el) => el.group);
      }

      var data = [];
      for (const x of res.data.detail) {
        // let value = [];
        // let usedValue = [];
        for (const n of x.data) {
          let index = data.findIndex((el) => el.label == n.name);
          if (index == -1) {
            var color = getRandomColor();
            data.push(
              {
                label: n.name,
                type: "bar",
                data: [n.value],
                group: true,

                backgroundColor: color,
              },
              {
                label: n.name,
                type: "bar",

                group: true,
                data: [n.value2],

                backgroundColor: color
                  .replace(")", ", 0.45)")
                  .replace("rgb", "rgba"),
              }
            );
          } else {
            data[index].data.push(n.value);
            data[index + 1].data.push(n.value2);
          }
        }
      }

      this.chartGenderData = data;

      this.isChartLoading = false;
    },
    async getCompletedRedeem() {
      this.isBusy = true;
      let payLoad = { ...this.filter };
      payLoad.Page = this.filterTable.page;
      payLoad.take = this.filterTable.take;
      payLoad.SortType = this.filterTable.SortType;
      payLoad.SortColumnId = this.filterTable.SortColumnId;

      for (const [keys, value] of Object.entries(payLoad)) {
        if (typeof value == "object") {
          payLoad[keys] = value.map((el) => el.id);
        }
      }
      const res = await this.$Axios.post(
        `${process.env.VUE_APP_REPORT_API}/dashboard/RedeemAnalysis/CompletedRedeem`,
        payLoad
      );

      this.items = res.data.detail.data;
      this.rows = res.data.detail.total;
      this.isBusy = false;
    },
    async getOptions() {
      const redeem = await this.$Axios(
        `${process.env.VUE_APP_REPORT_API}/dashboard/Filter/Redeem`
      );
      this.options.redeem = redeem.data.detail;
      // this.options.coupon = res.data.detail;
      const RedeemItems = await this.$Axios(
        `${process.env.VUE_APP_REPORT_API}/dashboard/Filter/RedeemItem`
      );
      this.options.redeemItems = RedeemItems.data.detail;
      const memberTier = await this.$Axios(
        `${process.env.VUE_APP_REPORT_API}/RevenueReport/CustomFilter`
      );
      this.options.memberTiers = memberTier.data.memberTiers;
      // this.stat = res.data.detail;
    },
    pagination(val) {
      this.filterTable.page = val;

      this.getCompletedRedeem();
    },

    handleChangeTake(val) {
      this.filterTable.page = 1;

      this.filterTable.take = val;
      this.getCompletedRedeem();
    },

    async exportExcel(mode, name) {
      try {
        this.filter.Mode = mode;
        let payLoad = { ...this.filter };
        for (const [keys, value] of Object.entries(payLoad)) {
          if (typeof value == "object") {
            payLoad[keys] = value.map((el) => el.id);
          }
        }
        this.$EventBus.$emit("showLoading");

        const res = await this.$Axios.post(
          `${process.env.VUE_APP_REPORT_API}/dashboard/Export/RedeemAnalysis`,
          payLoad,
          {
            responseType: "blob",
          }
        );

        let data = res;

        var fileURL = window.URL.createObjectURL(new Blob([data.data]));
        var fileLink = document.createElement("a");
        // var dateExcel = this.$moment().format("DDMMYYYYhhmmss");
        fileLink.href = fileURL;
        fileLink.setAttribute("download", name + `.xlsx`);
        document.body.appendChild(fileLink);
        fileLink.click();
        this.$EventBus.$emit("hideLoading");
      } catch (error) {
        console.error(error);
        this.errorAlert(error.message);
        this.$EventBus.$emit("hideLoading");
      }
    },
    filterActions() {
      if (this.dateTypeValue == 1) {
        this.filter.DateFrom = moment().subtract(1, "week").format();
        this.filter.DateTo = moment().format();
      } else if (this.dateTypeValue == 2) {
        this.filter.DateFrom = moment().subtract(30, "days").format();
        this.filter.DateTo = moment().format();
      } else if (this.dateTypeValue == 3) {
        this.filter.DateFrom = moment().subtract(1, "years").format();
        this.filter.DateTo = moment().format();
      } else {
        this.filter.DateFrom = moment(this.filter.DateFrom).format();
        this.filter.DateTo = moment(this.filter.DateTo).format();
      }

      this.getData();
    },
    clearFilter() {
      this.filter = {
        DateFrom: moment()
          .subtract(1, "months")
          .startOf("month")
          .format("YYYY-MM-DD"),
        DateTo: moment().endOf("month").format("YYYY-MM-DD"),
        Search: "",
        MemberTier: [],
        CouponCode: [],
        PrivilegeCode: [],
        Page: 1,
        GraphType: 1,
        take: 10,
      };

      this.$refs.sidebarFilter.hide();
      this.filterActions();
    },
    async exportCustomer() {
      try {
        let payLoad = { ...this.filter };
        for (const [keys, value] of Object.entries(payLoad)) {
          if (typeof value == "object") {
            payLoad[keys] = value.map((el) => el.id);
          }
        }

        this.$EventBus.$emit("showLoading");

        const res = await this.$Axios.post(
          `${process.env.VUE_APP_REPORT_API}/DashBoard/Export/redeem_customer`,
          payLoad
        );
        if (res.status == 200) {
          if (res.data.result)
            this.confirmAlert({
              message: "Do you want to download the file now?",
              title: "Success !",
              icon: "success",
              confirmButtonText: "Go To Page",
              cancelButtonText: "Close",
            }).then((val) => {
              if (val.isConfirmed) {
                // this.deleteItem(id);
                const routeData = this.$router.resolve({
                  name: "Customer Report List",
                });
                window.open(routeData.href, "_blank");
              }
            });
          else this.errorAlert(res.data.message);
        } else {
          this.errorAlert(res.data.message);
        }
        this.$EventBus.$emit("hideLoading");
      } catch (error) {
        console.error(error);
        this.errorAlert(error.message);
        this.$EventBus.$emit("hideLoading");
      }
    },
    exportModal() {
      this.$refs.ModalInputEmail.show();
    },
    handleSearch() {
      this.getProduct();
    },
    sidebarFilter() {
      this.$refs.sidebarFilter.show();
    },
    filterGraph(val) {
      this.filter.GraphType = val;
      this.getRedeemChart();
    },
    clearExportFilter() {
      this.filterExportFile = {
        email: "",
      };
    },
    sortingTable(ctx) {
      let index = this.fields.findIndex((el) => el.key == ctx.sortBy);
      index = index + 1;

      if (
        this.filterTable.SortType &&
        !ctx.sortDesc &&
        this.filterTable.SortColumnId == index
      ) {
        this.filterTable.SortColumnId = 0;
        this.filterTable.SortColumnId = 0;
        this.filterTable.sortBy = "";
        this.filterTable.sortDesc = "";
      } else {
        this.filterTable.SortColumnId = index;
        this.filterTable.SortType = ctx.sortDesc;
      }
      this.getCompletedRedeem();
    },
    getDataFilter() {
      return this.options.dateType.find((el) => el.value == this.dateTypeValue)
        .text;
    },
  },
};
</script>

<style>
.chart-title-name {
  font-size: 18px;
  font-weight: bold;
  color: black;
  /* margin-bottom: 0.75rem; */
}
.text-score {
  color: var(--primary-color);
  font-size: 42px;
}
.text-highlight {
  color: var(--primary-color);
  font-weight: bold;
  opacity: 1 !important;
}
.text-filter {
  opacity: 0.5;
}
</style>
